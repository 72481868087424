<template>
  <div
    class="tree-item"
    :class="[{ noIcon }]"
    :draggable="draggable"
    @drop.stop="editMode ? onDrop($event, item) : () => false"
    @dragenter.prevent.stop="onDragEnter($event)"
    @dragover.prevent.stop="() => false"
    @dragleave.prevent.stop="onDragLeave($event)"
    @dragstart.stop="dragStart($event, item)"
    @dragend.stop="onDragEnd($event)"
  >
    <div
      key="parent"
      class="tree-item__body"
      :class="{
        active,
        folder: isFolder,
        'active-child': hasActiveChild,
        drag: editMode && !renaming && !active && !isFolder
      }"
      @click="toggleItem"
    >
      <svg
        v-if="!loading && !noIcon"
        v-svg
        :symbol="iconByItem(item)"
        role="presentation"
        class="tree-item__body-icon"
      />
      <span
        v-else-if="!noIcon"
        class="tree-item__body-loader"
      />
      <input
        v-if="renaming && active && editMode"
        ref="nameInput"
        v-model="newName"
        class="tree-item__input"
        @click.stop
      >
      <span
        v-else
        class="tree-item__body-name"
      >
        {{ item.name }}
      </span>
      <tools
        v-if="(active && !noTools) || showTools || (editMode && active)"
        :tool-kit="toolKit"
        :tool-click="toolClick"
        :mini-tools="miniTools"
        :item="item"
        :renaming="renaming"
        :edit-mode="editMode"
        class="tree-item__body-tools"
        @save="saveRenamed"
        @rename="toggleRename(true)"
        @cancel="toggleRename(false)"
      />
    </div>
    <component
      :is="noAnimations ? 'div' : 'transition'"
      v-if="isFolder"
      v-show="active"
      :name="noAnimations ? '' : 'tree-item'"
      :mode="noAnimations ? '' : 'out-in'"
    >
      <div
        v-show="active"
        key="children"
        class="tree-item__children-list"
      >
        <tree-item
          v-for="child in children"
          :key="child.id"
          :item="child"
          :class="{ child: true }"
          :filter-text="filterText"
          :show-tools="showTools"
          :no-animations="noAnimations"
          :tool-click="toolClick"
          :no-tools="noTools"
          :edit-mode="editMode"
          :node-click="nodeClick"
          :tool-kit="toolKit"
          :icon-by-item="iconByItem"
          :active-nodes="activeNodes"
          :loading-nodes="loadingNodes"
          :mini-tools="miniTools"
          :no-icon="noIcon"
          @updateNode="updateNode"
        />
      </div>
    </component>
  </div>
</template>

<script>
export default {
  name: 'TreeItem',
  components: {
    tools: () => import('./tools')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    filterText: {
      type: String,
      required: true
    },
    showTools: {
      type: Boolean,
      default: false
    },
    noAnimations: {
      type: Boolean,
      default: false
    },
    noTools: {
      type: Boolean,
      default: false
    },
    miniTools: {
      type: Boolean,
      default: false
    },
    toolClick: {
      type: Function,
      default: () => null
    },
    nodeClick: {
      type: Function,
      default: () => null
    },
    toolKit: {
      type: Function,
      default: () => []
    },
    iconByItem: {
      type: Function,
      default: () => 'folder-layer'
    },
    activeNodes: {
      type: Array,
      default: () => []
    },
    loadingNodes: {
      type: Array,
      default: () => []
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      loading: false,
      newName: '',
      renaming: false,
      hasActiveChild: false
    }
  },
  computed: {
    draggable() {
      return this.editMode && !this.renaming && !this.isFolder
    },
    isFolder() {
      return !!this.item?.children?.length
    },
    children() {
      if (this.filterText && this.item.children?.length) {
        return this.filterChildren(this.item.children)
      } else {
        return this.item.children
      }
    },
    hashedActiveNodes() {
      return this.activeNodes.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    },
    hashedLoadingNodes() {
      return this.loadingNodes.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    }
  },
  watch: {
    hashedActiveNodes(hash) {
      this.updateActiveNodes(hash)
    },
    hashedLoadingNodes(hash) {
      this.updateLoadingNodes(hash)
    }
  },
  beforeUpdate() {
    this.updateLoadingNodes()
    this.updateActiveNodes()
  },
  mounted() {
    const { id } = this.item
    this.active = !!this.hashedActiveNodes[id]
    this.loading = !!this.hashedActiveNodes[id]
  },
  methods: {
    onDragEnter(e) {
      if (!this.editMode || this.renaming) return

      const { target } = e

      if (target.classList.contains('tree-item__body')) {
        target.classList.add('tree-item__drag-hover')
      }
    },
    onDragLeave(e) {
      if (!this.editMode || this.renaming) return
      e.stopPropagation()

      const { target } = e

      if (target.classList.contains('tree-item__drag-hover')) {
        target.classList.remove('tree-item__drag-hover')
      }
    },
    onDragEnd(e) {
      if (!this.editMode || this.renaming) return

      const { target } = e
      setTimeout(() => {
        target.style.opacity = '1'
      }, 0)
    },
    onDrop(e, host) {
      this.onDragLeave(e)
      if (!this.editMode || this.renaming) return

      const [id, parent_id, name] = JSON.parse(e.dataTransfer.getData('text'))

      const node = []

      if (parent_id === host.id) {
        // remove frome folder
        node.push({ id, parent_id: null })
      } else if (
        id !== host.id && // another node
        !host.children?.length && // !folder
        !host.parent_id // host !== folder
      ) {
        // Create new folder => paste both nodes => remove nodes from list
        const disabled = true
        node.push({
          name: 'Новая папка',
          children: [
            { source_id: id, name },
            { source_id: host.id, name: host.name }
          ]
        })
        node.push({ id, disabled })
        node.push({ id: host.id, disabled })
      } else if (
        id !== host.id && // another node
        host.children?.length && // host === folder
        (!host.parent_id || (host.parent_id && parent_id !== host.parent_id)) // !same folder
      ) {
        node.push({ id, parent_id: host.id })
      } else {
        return
      }

      this.$emit('updateNode', node)
    },
    dragStart(e, { id, parent_id, name }) {
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.effectAllowed = 'move'

      const { target } = e
      setTimeout(() => {
        target.style.opacity = 0.5
      }, 0)

      const item = JSON.stringify([id, parent_id, name])
      e.dataTransfer.setData('text/plain', item)
    },
    updateActiveNodes(hash = this.hashedActiveNodes) {
      const { id, children } = this.item
      if (!this.isFolder) {
        this.active = !!hash[id]
      } else {
        this.hasActiveChild = children.find(item => !!hash[item.id])
      }
    },
    updateLoadingNodes(hash = this.hashedLoadingNodes) {
      const { id, datatype } = this.item
      if (datatype !== 'model') {
        this.loading = !!hash[id]
      }
    },
    updateNode(data) {
      this.$emit('updateNode', data)
    },
    toggleRename(value) {
      this.renaming = value ?? !this.renaming
      if (this.renaming) {
        this.newName = this.item.name
        this.$nextTick(() => this.$refs?.nameInput.focus())
      } else {
        this.newName = ''
      }
    },
    saveRenamed() {
      this.updateNode([{ id: this.item.id, name: this.newName }])
      this.toggleRename(false)
    },
    filterChildren(children) {
      const searchFields = ['name']
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) {
        return children
      }
      const res = children.filter(e => {
        const filtered = searchFields
          .reduce((acc, curr) => {
            acc += e[curr]
            return acc
          }, '')
          .toLowerCase()

        const res = filtered.includes(query)
        if (res) this.active = true

        return res
      })
      return res
    },
    async toggleItem() {
      const { item } = this
      if (this.isFolder) {
        this.active = !this.active
      } else {
        if (this.renaming) {
          this.renaming = false
          this.newName = ''
        }
        try {
          this.loading = true
          await this.nodeClick(item)
        } catch (e) {
          throw new Error(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
